import { RetailStore } from '@akinon/next/types';

export * from '@theme/types/widgets';

export type RegisterFormType = {
  email: string;
  first_name: string;
  last_name: string;
  password: string;
  phone: string;
  confirm: boolean;
  kvkk_confirm: boolean;
  subscription_confirm?: boolean;
  email_allowed?: boolean;
  sms_allowed?: boolean;
  call_allowed?: boolean;
  formType: string;
  locale: string;
  date_of_birth?: string;
  communication_allowed?: boolean;
  email_sms_validation?: boolean;
};

export type GiftListCreateFormType = {
  list_name: string;
  list_status: string;
};

export enum GiftListStatus {
  PRIVATE = 'private',
  PUBLIC = 'public'
}
export type RegisterClubCardFormType = {
  email: string;
  first_name: string;
  last_name: string;
  password: string;
  password_confirm: string;
  phone: string;
  confirm: boolean;
  kvkk_confirm: boolean;
  subscription_confirm?: boolean;
  email_allowed?: boolean;
  sms_allowed?: boolean;
  call_allowed?: boolean;
  formType: string;
  locale: string;
  date_of_birth?: string;
  country: string;
  city: string;
  township: string;
  district: string;
  line: string;
};

export type LoginFormType = {
  email: string;
  password: string;
  formType: string;
  locale: string;
};

export type ContactFormType = {
  full_name: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  subject: string;
  message: string;
  order?: string;
  country_code?: string;
  order_needed?: boolean;
  info_confirm?: boolean;
  kvkk_confirm?: boolean;
  file?: FileList;
};

export type ClickCollectFormType = {
  first_name: string;
  last_name: string;
  phone: string;
};

export enum WIDGET_TYPE {
  category = 'category',
  multiselect = 'multiselect'
}

export interface CreditCardForm {
  card_holder: string;
  card_number: string;
  card_month: string;
  card_year: string;
  card_month_year: string;
  card_cvv: string;
  non_field_errors: string;
  installment: number;
  agreement: boolean;
}

export interface PriceProps {
  currencyCode?: string;
  useCurrencySymbol?: boolean;
  useCurrencyAfterPrice?: boolean;
  useCurrencySpace?: boolean;
  useNegative?: boolean;
  useNegativeSpace?: boolean;
}

export interface SeoProps {
  title?: string;
  description?: string;
  keywords?: string;
  itemPropName?: string;
  itemPropDescription?: string;
  itemPropImage?: string;
  twitterCard?: string;
  twitterTitle?: string;
  twitterDescription?: string;
  twitterData1?: string;
  twitterLabel1?: string;
  ogTitle?: string;
  ogImage?: string;
  ogDescription?: string;
  ogPriceAmount?: string;
  ogPriceCurrency?: string;
}

export enum ORDER_STATUS {
  CANCELLATION_WAITING = '50',
  CANCELLED = '100',
  WAITING = '200',
  PAYMENT_WAITING = '300',
  CONFIRMATION_WAITING = '350',
  APPROVED = '400',
  PREPARING = '450',
  SHIPPED = '500',
  SHIPPED_AND_INFORMED = '510',
  READY_FOR_PICKUP = '520',
  ATTEMPTED_DELIVERY = '540',
  DELIVERED = '550',
  REFUNDED = '600'
}

export type InvoiceItemType = {
  Sap_Fatura_No: number;
  Tip: string;
  Fatura_Tarihi: string;
  Net_Tutar: string;
  Vergi: string;
  Toplam_Tutar: string;
  Para_Birimi: string;
  Fatura_No: string;
  Magaza_Kodu: number;
  Magaza_Adi: string;
  Musteri_No: string;
  Musteri_Adi: string;
  Iade_Mi?: string;
};

export type ClickCollectRetailStore = {
  longitude?: number;
  latitude?: number;
  retail_store: RetailStore;
};
export type RetailStoresList<T> = {
  count: number;
  next: null;
  previous: null;
  results: T[];
};
